import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import useAccountContext from '@/hooks/context/useAccountContext';
import MetaAPI from '@/network/MetaAPI';
import { handleApiError } from '@/utility/api';

import Loading from '../components/utility/Loading';

const NUMBER_OF_SECONDS = 5;
const INTERVAL_DURATION = 1000;
const TIMEOUT_DURATION = NUMBER_OF_SECONDS * 1000;

const MetaCallbackPage = () => {
  const { accountId, refetchAccount } = useAccountContext();
  const [params] = useSearchParams();
  const [closingIn, setClosingIn] = useState<number>(NUMBER_OF_SECONDS);

  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const authCode = useMemo(() => location.hash.slice(14, location.hash.indexOf('&')), [location]);

  const loginToMeta = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!accountId || !authCode) return;

      await MetaAPI.loginToMeta({ accountId, accessToken: authCode });
      await refetchAccount();
      window.opener.postMessage('Meta logged in', '*');
      window.close();
    } catch (error: unknown) {
      handleApiError({ error });
    } finally {
      setIsLoading(false);
    }
  }, [accountId, authCode, refetchAccount]);

  useEffect(() => {
    loginToMeta();
  }, [loginToMeta]);

  const timeout = useCallback(() => {
    setTimeout(() => {
      window.opener.postMessage('Meta log in denied', '*');
      window.close();
    }, TIMEOUT_DURATION);
  }, []);

  const interval = useCallback(() => {
    setInterval(() => {
      setClosingIn(closingIn - 1);
    }, INTERVAL_DURATION);
  }, [closingIn]);

  useEffect(() => {
    if (params.get('error')) {
      timeout();
      interval();
    }
  }, [params, timeout, interval]);

  if (params.get('error') === 'access_denied') {
    return (
      <div className="mt100 ml-auto mr-auto text-center">
        <h3>{params.get('error') === 'access_denied' ? 'Access denied' : params.get('error')}</h3>
        <p className="mt20">This window will close in {closingIn}...</p>
      </div>
    );
  }

  return <>{isLoading && <Loading />}</>;
};

export default MetaCallbackPage;
